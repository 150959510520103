var textNodesUnder;

textNodesUnder = function(el) {
  var a, n, walk;
  n = void 0;
  a = [];
  walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null, false);
  while (n = walk.nextNode()) {
    if (/\S/.test(n.nodeValue)) {
      a.push(n);
    }
  }
  return a;
};

document.addEventListener('turbolinks:load', function() {
  var carousel, carousel_controls, carousel_inner;
  $('.filter_by_select').selectpicker();
  $('.rating :radio').change(function() {
    return $(this).closest('form').find('#comment_rating').val($(this).val());
  });
  if ($('#justified-gallery').length) {
    $('#justified-gallery').justifiedGallery({
      rowHeight: 150,
      margins: 15,
      border: 0,
      captions: false,
      lastRow: 'justify',
      thumbnailPath: function(currentPath, width, height, image) {
        if (height > 240 && height <= 480) {
          return currentPath.replace('sm-', 'md-');
        } else if (height > 480) {
          return currentPath.replace('sm-', 'lg-');
        }
      }
    });
  }
  if ($('#custom-justified-gallery').length) {
    $('#custom-justified-gallery').justifiedGallery($.extend($('#custom-justified-gallery').data('justified-gallery-options'), {
      thumbnailPath: function(currentPath, width, height, image) {
        if (height > 240 && height <= 480) {
          return currentPath.replace('sm-', 'md-');
        } else if (height > 480) {
          return currentPath.replace('sm-', 'lg-');
        }
      }
    }));
  }
  // if ($('.custom_text').length) {
  //   $('.custom_text').each(function() {
  //     var url_base;
  //     url_base = $(this).data('hashtag-url-base');
  //     return $.each(textNodesUnder(this), function(index, node) {
  //       return $(node).replaceWith(twitter.autoLinkHashtags(node.nodeValue, {
  //         'hashtagUrlBase': url_base + '?q='
  //       }));
  //     });
  //   });
  // }
  if ($('select.filter_by_select').length) {
    $('select.filter_by_select').change(function() {
      var url;
      url = new URL($('.filter-block').data('url'));
      if ($(this).val() === '') {
        url.searchParams["delete"]($(this).data('url-search'));
      } else {
        url.searchParams.set($(this).data('url-search'), $(this).val());
      }
      return Turbolinks.visit(url.toString());
    });
  }
  if ($('#collectionModal').length) {
    carousel = $('#collectionCarousel');
    carousel_inner = carousel.find('.carousel-inner');
    carousel_controls = $('#collectionCarousel').find('a');
    return $('#collectionModal').on('show.bs.modal', function(e) {
      var active_slide, images, opening_img;
      carousel_inner.empty();
      opening_img = $(e.relatedTarget);
      images = opening_img.closest('.preview-theme').find('.linked-image');
      if (images.length === 1) {
        carousel_controls.addClass('d-none');
      } else {
        carousel_controls.removeClass('d-none');
      }
      active_slide = images.index(opening_img);
      return images.each(function(i) {
        var div;
        div = $('<div>', {
          "class": 'carousel-item'
        });
        if (i === active_slide) {
          div.addClass('active');
        }
        div.append($('<img>', {
          src: $(this).attr('href')
        }));
        return carousel_inner.append(div);
      });
    });
  }
});

document.addEventListener('turbolinks:before-cache', function() {
  return $('select.filter_by_select').selectpicker('destroy');
});
